import * as React from "react";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ActionList from "components/ActionList/ActionList";
import {DynamicWorkerServiceResource} from "../../client/resources/dynamicWorkerServiceResource";
import {RouteComponentProps} from "react-router-dom";
import ExternalLink from "../../components/Navigation/ExternalLink";
import externalSystemLinks from "../../externalSystemLinks";
import OverflowMenu from "../../components/Menu/OverflowMenu";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import buildDynamicWorkerServiceBreadcrumbTitle from "./buildDynamicWorkerServiceBreadcrumbTitle";

interface State extends DataBaseComponentState {
    dynamicWorkerService?: DynamicWorkerServiceResource;
}

type Props = RouteComponentProps<{ id: string }>;

export class DynamicWorkerServiceDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const id = this.props.match.params.id;

        const dynamicWorkerService =await repository.DynamicWorkerServices.get(id);

        this.setState({
            dynamicWorkerService
        });
    }

    render() {

        const id = this.props.match.params.id;
        
        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={buildDynamicWorkerServiceBreadcrumbTitle(this.state.dynamicWorkerService)}
                            sectionControl={<ActionList actions={this.getActions()}/>}>
            {this.state.dynamicWorkerService && this.getBody()}
        </PaperLayout>;
    }

    private getActions() {
        if (!this.state.dynamicWorkerService) return [];
        
        return [
            <ExternalLink
                href={externalSystemLinks.seq.dynamicWorkerService(this.state.dynamicWorkerService.InstanceId)}>
                Seq
            </ExternalLink>,
            <ExternalLink
                href={externalSystemLinks.sumoLogic.dynamicWorkerServiceLogs(this.state.dynamicWorkerService.InstanceId)}>
                SumoLogic Logs
            </ExternalLink>,
            <ExternalLink
                href={this.state.dynamicWorkerService.Url}>
                Status
            </ExternalLink>,
            <ExternalLink 
                href={externalSystemLinks.seq.dynamicWorkerInitialLeaseTime(this.state.dynamicWorkerService.InstanceId)}>
                Initial Lease Time logs
            </ExternalLink>,
            <OverflowMenu menuItems={[OverflowMenu.navItem("Edit", dynamicWorkerServicesRouteLinks.service(this.state.dynamicWorkerService.Id).edit)]} />
        ];
    }

    private getBody() {
        const dynamicWorkerService = this.state.dynamicWorkerService;
        return dynamicWorkerService && <>
            <FormSectionHeading title="General"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>ID</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Id}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Name</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Name}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Instance ID</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.InstanceId}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Leasing API Web App Resource ID</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.LeasingApiWebAppResourceId}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>URL</DataTableRowHeaderColumn>
                        <DataTableRowColumn><ExternalLink href={dynamicWorkerService.Url}>{dynamicWorkerService.Url}</ExternalLink> </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Username</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.Username}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Seq Dashboard Name</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.SeqDashboardName}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>App Registration Audience</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{dynamicWorkerService.AppRegistrationAudience}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
        </>;
    }
}

export default DynamicWorkerServiceDetail;
